import { Loader } from "@googlemaps/js-api-loader";
import axios from "axios";
import React, { useRef } from "react";
import { useContext } from "react";
import { getBackendApiUrl } from "../utils/getBackendApiUrl";
import { goToSubscriptionProblemPage } from "../utils/goToSubscriptionProblemPage";
import { storeTenantInfo } from "../utils/storeTenantInfo";
import showClosableToastAlert from "../utils/alerts/showClosableToastAlert";
import { getURLOfRedirectPages } from "../utils/getURLOfRedirectPages";
import initializeAxios from "../services/initializeAxios";

const GOOGLE_MAPS_API_KEY = window.__ENV__.GOOGLE_MAPS_API_KEY;
const googleMapLibraries = ["marker"];

const ExternalPagesStoreContext = React.createContext({
  /* eslint no-unused-vars:off */
  initializeAxiosWithoutLogin: token => {},
});

const ExternalPagesStoreProvider = props => {
  initializeAxios(false, null, null);

  const googleMapLoaderRef = useRef(null);

  const loader = new Loader({
    apiKey: GOOGLE_MAPS_API_KEY,
    libraries: googleMapLibraries,
    version: "weekly",
  });

  if (loader && !googleMapLoaderRef?.current) {
    googleMapLoaderRef.current = loader;
    loader.importLibrary("maps").catch(() => {
      showClosableToastAlert(
        "error",
        "Google Maps failed to load",
        "This may result in the maps not working correctly, please refresh the page"
      );
    });
  }

  const initializeAxiosWithoutLoginHandler = token => {
    try {
      const backendApiUrl = getBackendApiUrl();
      const { accessDeniedPageUrl, subscriptionProblemPageUrl } =
        getURLOfRedirectPages();
      axios.defaults.baseURL = backendApiUrl;
      axios.defaults.headers.common = {
        Authorization: `bearer ${token}`,
      };
      axios.interceptors.response.use(
        res => {
          storeTenantInfo(res);
          return res;
        },
        err => {
          if ([403].includes(err.response?.status)) {
            window.location.replace(accessDeniedPageUrl);
          } else if ([462].includes(err.response?.status)) {
            const message = err.response.data.message;
            goToSubscriptionProblemPage(subscriptionProblemPageUrl, message);
          } else if ([463].includes(err.response?.status)) {
            const message = err.response.data.message;
            goToSubscriptionProblemPage(subscriptionProblemPageUrl, message);
          }
          return Promise.reject(err);
        }
      );
    } catch (err) {
      console.error(`Initialize axios failed | error: ${err}`);
    }
  };

  const ExternalPagesStoreValue = {
    initializeAxiosWithoutLogin: initializeAxiosWithoutLoginHandler,
  };

  return (
    <ExternalPagesStoreContext.Provider value={ExternalPagesStoreValue}>
      {props.children}
    </ExternalPagesStoreContext.Provider>
  );
};

export default ExternalPagesStoreProvider;
export const useExternalPagesStore = () =>
  useContext(ExternalPagesStoreContext);
