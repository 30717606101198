/**
 * Returns true if the browser supports sessionStorage, false otherwise.
 * @function isSessionStorageAvailable
 * This function is intentionally simple and does not attempt to handle
 * edge cases. If the browser supports sessionStorage, but has reached
 * its storage limit, for example, isSessionStorageAvailable will return
 * true, and the subsequent attempt to use sessionStorage will throw an
 * exception.
 * @returns {boolean} whether sessionStorage is supported
 */
export function isSessionStorageAvailable() {
  try {
    const testKey = "__test__";
    sessionStorage.setItem(testKey, "test");
    sessionStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}
