import { isLocalStorageAvailable } from "./isLocalStorageAvailable";

/**
 * Store tenant organization information in local storage.
 * @function storeTenantInfo
 * This function extracts the tenant organization information from the response headers and stores it in the local storage under the key "tenantOrg". It checks if the stored organization information is different from the received one before updating the local storage.
 * @param {Object} response - The response object from which the tenant organization information is extracted.
 */
export function storeTenantInfo(response) {
  if (isLocalStorageAvailable()) {
    const tenantOrg = response.headers["tenant-org"] || "";
    const storedTenantOrg = localStorage.getItem("tenantOrg");
    if (!storedTenantOrg || (storedTenantOrg && storedTenantOrg != tenantOrg)) {
      localStorage.setItem("tenantOrg", tenantOrg);
    }
  }
}
