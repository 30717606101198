/**
 * Checks whether the given pathname points to an external page or not.
 * @function checkExternalPage
 * @param {string} pathname The URL pathname to check.
 * @return {boolean} true if the pathname points to an external page, false otherwise.
 */
export function checkExternalPage(pathname) {
  const pathnameParts = pathname?.split("/")?.filter(part => part);
  const firstPartOfPathname = Array.isArray(pathnameParts)
    ? pathnameParts[0]
    : null;

  if (!firstPartOfPathname) return false;

  const isExternalPage = [
    "collect-data",
    "campaign-data-collect",
    "interview",
    "edit-interview",
    "rejection-feedback",
  ].includes(firstPartOfPathname);
  return isExternalPage;
}
