import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import initializeAxios from "../services/initializeAxios";
import { isSessionStorageAvailable } from "../utils/isSessionStorageAvailable";
import { useTranslation } from "react-i18next";
import showWarningMessage from "../utils/alerts/showWarningMessage";

const TenantsAuthContext = React.createContext({
  /* eslint no-unused-vars:off */
  token: "",
  isLoggedIn: false,
  login: token => {},
  logout: () => {},
  idleTimerPrompted: false,
  updateIdleTimerPrompted: isPrompted => {},
});

const TenantsAuthProvider = props => {
  const { t } = useTranslation();

  let initialToken = null;
  if (isSessionStorageAvailable()) {
    const storedToken = sessionStorage.getItem("token");
    if (storedToken) initialToken = storedToken;
  } else {
    showWarningMessage(t("storage_required"), t("storage_required_msg"));
  }

  const [token, setToken] = useState(initialToken);
  const [idleTimerPrompted, setIdleTimerPrompted] = useState(false);

  const isUserLoggedIn = !!token;

  const logoutHandler = async () => {
    setToken(null);
    if (isSessionStorageAvailable()) {
      sessionStorage.removeItem("token");
    }
    delete axios.defaults.headers.common["Authorization"];
    setIdleTimerPrompted(false);
  };

  const loginHandler = token => {
    if (isSessionStorageAvailable()) {
      sessionStorage.setItem("token", token);
    }
    setToken(token);
  };

  initializeAxios(isUserLoggedIn, { token: token }, logoutHandler);

  const tenantsAuthContextValue = {
    token: token,
    isLoggedIn: isUserLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    idleTimerPrompted: idleTimerPrompted,
    updateIdleTimerPrompted: setIdleTimerPrompted,
  };

  return (
    <TenantsAuthContext.Provider value={tenantsAuthContextValue}>
      {props.children}
    </TenantsAuthContext.Provider>
  );
};

export default TenantsAuthProvider;
export const useTenantsAuth = () => useContext(TenantsAuthContext);
