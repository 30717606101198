/**
 * Returns true if the browser supports localStorage, false otherwise.
 * @function isLocalStorageAvailable
 * This function is intentionally simple and does not attempt to handle
 * edge cases. If the browser supports localStorage, but has reached
 * its storage limit, for example, isLocalStorageAvailable will return
 * true, and the subsequent attempt to use localStorage will throw an
 * exception.
 * @returns {boolean} whether localStorage is supported
 */
export function isLocalStorageAvailable() {
  try {
    const testKey = "__test__";
    localStorage.setItem(testKey, "test");
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}
